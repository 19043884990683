.DesktopHeader {
	width: 100vw;
	height: 50px;
	color: var(--attach_picker_tab_active_text);
}

.DesktopHeader > .leftButton:hover {
	cursor: pointer;
}
.online .Avatar__in:after {
	z-index: 9999;
	bottom: 1%;
	right: 1%;
	border: 2px solid var(--background_content);
	height: 9px;
	width: 9px;
	content: "";
	position: absolute;
	background-color: #8ac176;
	border-radius: 50%;
}

.online_mobile .Avatar__in:after {
	z-index: 99999;
	bottom: 3%;
	right: 3%;
	height: 15px;
	width: 9px;
	background-color: transparent;
	background-image: url(/static/media/mobile.024259c8.svg);
	border: 0px;
	border-radius: 2px;
	background-repeat: no-repeat;
}

.BlockName {
	display: flex;
	vertical-align: middle;
	align-items: center;
}

.VerifiedIcon {
	color: var(--icon_name);
	margin-left: 4px;
}
.WelcomeGallery {
	height: calc(100vh - 54px);
}
.WelcomeOneBlock {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.WelcomeTextBlock {
	min-height: 135px;
	padding:10px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.WelcomeTextBlock_desktop {
	min-height: 135px;
	padding:10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeTitle {
	font-size: 22px;
	text-align: center;
}

.WelcomeDescription {
	font-size: 16px;
	color: var(--content_placeholder_text);
}

.WelcomeImg {
	height: 100%;
	padding-top: 10px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.WelcomeImgImg {
	max-width: 45vw;
}

.WelcomeImgImg_phone {
	max-height: calc(100vh - 155px);
	max-width: 85vw;
}
.titleContainer {
	flex: 0 0 auto;
	padding: 15px 6px;
	margin-bottom: 3px;
	color: #656565;
}

.mainMenuContainer {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #e7e8ec;
	border-width: thin;
	padding: 0 10px 0 10px;
	
	-webkit-overflow-scrolling: touch !important;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
}

.mainMenuContainer .active {
	color: var(--header_text_alternate);
	border-bottom: 3px solid var(--attach_picker_tab_active_text);
	margin-bottom: 0px;
}

.mainMenuContainer::-webkit-scrollbar {
	display: none;
}

.mainMenuContainer::-webkit-scrollbar-button {
	display: none;
}
.CardPreviewBlock_desc {
	display: flex;
}

.CardPreviewImg_desc {
	width: 30vw;
	max-height: 111px;
	border-radius: 10px;
	margin-right: 12px;
}

.CardPreviewTitle_desc {
	display: block;
	font-size: 1.4em;
	font-weight: bold;
}

.CardPreviewText_desc {
	padding-top: 12px;
}

.CardPreviewDownBlock_desc {
	padding-top: 12px;
}

.CardPreviewImg_mob {
	max-width: 70vw;
	max-height: 111px;
	border-radius: 10px;
}

.CardPreviewTitle_mob {
	display: block;
	font-size: 1.4em;
	font-weight: bold;
	padding-bottom: 12px;
	text-align: center;
}

.badVersion {
	padding-top: 30vh;
	box-sizing: border-box;
	text-align: center;
	width: 100vw;
}

.badVersionItem {
	width: 100vw;
}
body[scheme="client_dark"] {
	color: #fff;
}

body[scheme="space_gray"] {
	color: #fff;
}


body::-webkit-scrollbar-track {
	background-color: var(--separator_common);
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: var(--attach_picker_tab_inactive_text);
	border-radius: 6px;
}

.flex_start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex_end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex_arrow {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pb-0 {
	padding-bottom: 0px;
}
.pt-0 {
	padding-top: 0px;
}
.pt-5 {
	padding-top: 5px;
}
.pb-5 {
	padding-bottom: 5px;
}
.pr-5 {
	padding-right: 5px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.mr-5 {
	margin-right: 5px;
}
.ml-5 {
	margin-left: 5px;
}

/* Search android fix */
.Search--android {
	background: var(--search_bar_background);
}

.Search--android.Search--default .Search__input { 
	color: var(--text_primary);
}
.Search--android.Search--default .Search__container {
	background-color: var(--background_content);
}

.WelcomeGallery {
	height: calc(100vh - 54px);
}
.WelcomeOneBlock {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.WelcomeTextBlock {
	min-height: 135px;
	padding:10px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.WelcomeTextBlock_desktop {
	min-height: 135px;
	padding:10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.WelcomeTitle {
	font-size: 22px;
	text-align: center;
}

.WelcomeDescription {
	font-size: 16px;
	color: var(--content_placeholder_text);
}

.WelcomeImg {
	height: 100%;
	padding-top: 10px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.WelcomeImgImg {
	max-width: 45vw;
}

.WelcomeImgImg_phone {
	max-height: calc(100vh - 155px);
	max-width: 85vw;
}
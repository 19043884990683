.CardPreviewBlock_desc {
	display: flex;
}

.CardPreviewImg_desc {
	width: 30vw;
	max-height: 111px;
	border-radius: 10px;
	margin-right: 12px;
}

.CardPreviewTitle_desc {
	display: block;
	font-size: 1.4em;
	font-weight: bold;
}

.CardPreviewText_desc {
	padding-top: 12px;
}

.CardPreviewDownBlock_desc {
	padding-top: 12px;
}

.CardPreviewImg_mob {
	max-width: 70vw;
	max-height: 111px;
	border-radius: 10px;
}

.CardPreviewTitle_mob {
	display: block;
	font-size: 1.4em;
	font-weight: bold;
	padding-bottom: 12px;
	text-align: center;
}
